import React, { useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Title from '../../molecules/title/title';
import VideoItem from '../../molecules/video-item/VideoItem';
import { getCommonOrganismData } from '../../utils/functions';
import { Wrapper } from '../../utils/components';
import withPreview from '../../utils/with-preview';
import classNames from 'classnames';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import './VideoGallery.scss';
import AnchorHooking from '../../molecules/anchor-hooking/AnchorHooking';
import PlayFull from '../../atoms/static-icons/play-full';

function VideoGallery({ data, considerMenuSpace }) {
  const {
    anchorLabel,
    backgroundColor,
    htmlTagWrapper,
    marginTop,
    title,
    titleHeadingTag,
    titleIconId,
    titleIconThickness,
  } = getCommonOrganismData(data);
  const videoId = data.videoId;

  const [current, setCurrent] = useState(0);

  return (
    <Wrapper
      data-module-code='C-31'
      data-module-name='videogallery'
      tag={htmlTagWrapper}
      className={classNames(`videogallery ${marginTop}`, {
        'videogallery--colored-background': backgroundColor !== 'transparent',
      })}
      style={{ '--bg': `var(--${backgroundColor})` }}
    >
      <AnchorHooking id={anchorLabel} belowMenu={considerMenuSpace} />

      {(title || titleIconId) && (
        <Title
          tag={titleHeadingTag}
          titleIconId={titleIconId}
          titleIconThickness={titleIconThickness}
          style={
            backgroundColor !== 'transparent'
              ? { '--module-title-color': 'var(--bianco)' }
              : { '--module-title-color': 'var(--petrolio-20k)' }
          }
        >
          {title}
        </Title>
      )}
      <div className='videogallery__container wrapper'>
        <div className='videogallery__player'>
          {videoId[current] && (
            <VideoItem
              videoId={videoId[current].value}
              videoSite={videoId[current]?.content?.videoProvider?.value?.[0]}
              image={videoId[current]?.content?.videoImage?.poster}
              imageDescription={
                videoId[current]?.content?.videoImage?.value?.title
              }
            />
          )}
        </div>
        <div
          className='videogallery__list-wrapper'
          style={
            backgroundColor !== 'transparent'
              ? {
                  '--border-list-color': 'var(--verde-turchese-darker-30a)',
                  '--color-bar': 'var(--petrolio-20k)',
                }
              : {
                  '--border-list-color': 'var(--petrolio-20k)',
                  '--bg': 'var(--bianco)',
                  '--color-bar': 'var(--petrolio)',
                }
          }
        >
          <SimpleBar className='videogallery__list' autoHide={false}>
            {videoId?.map((item, index) => (
              <button
                key={item.value}
                onClick={() => setCurrent(index)}
                className={classNames('videogallery__list-item', {
                  'videogallery__list-item--active': index === current,
                })}
                style={
                  backgroundColor !== 'transparent'
                    ? {
                        '--bg-color-active-item': 'var(--petrolio-20k)',
                      }
                    : {
                        '--bg-color-active-item':
                          'var(--verde-turchese-20k-30a)',
                      }
                }
              >
                {item.content?.videoImage?.thumb && (
                  <>
                    <span className='videogallery__list-icon'>
                      <PlayFull />
                    </span>
                    <GatsbyImage
                      image={getImage(item.content.videoImage.thumb)}
                      alt={item.content.videoImage.value.title || ''}
                      objectFit='contain'
                    />
                  </>
                )}
                <span
                  className='videogallery__list-title'
                  style={
                    backgroundColor !== 'transparent'
                      ? { '--text-color': 'var(--bianco2)' }
                      : { '--text-color': 'var(--petrolio-20k)' }
                  }
                >
                  {item.content?.videoTitle?.value}
                </span>
              </button>
            ))}
          </SimpleBar>
        </div>
      </div>
    </Wrapper>
  );
}

export default withPreview(VideoGallery);
